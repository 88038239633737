<template>
    <div class="row">
        <div
            v-if="groups && groups.length > 0"
            class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group"
        >
            <label for="dtp_group_by">Group By</label>
            <dx-select-box
                :input-attr="{ id: 'dtp_group_by', automation_id: 'dtp_group_by' }"
                :items="groups"
                value-expr="id"
                display-expr="name"
                v-model="grouping"
            />
        </div>
        <div v-if="hasQuickDate" class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
            <label for="dtp_quick_date">Quick Dates</label>
            <dx-select-box
                :input-attr="{ id: 'dtp_quick_date', automation_id: 'dtp_quick_date' }"
                :items="quickDates"
                display-expr="name"
                value-expr="id"
                v-model="quickDateValue"
            />
        </div>
        <div v-if="hasSpeedDate" class="col col-4 col-sm-12 col-md-6 col-lg-4 form-group">
            <label for="dtp_speed_date">Speed Dates</label>
            <dx-select-box
                :input-attr="{ id: 'dtp_speed_date', automation_id: 'dtp_speed_date' }"
                :items="speedDates"
                display-expr="name"
                value-expr="id"
                v-model="speedDateValue"
            />
        </div>
        <dynamic-parameter
            v-for="(pm) in userParameters"
            :key="pm.name"
            ref="parameter"
            class="col col-4 col-sm-12 col-md-6 col-lg-4"
            :parameter-definition="pm"
            :value="parameterValuesLocal[pm.name]"
            :all-parameters="parameterValuesLocal"
            :is-initializing="isInitializing"
            @value-change="onParameterValueChange"
            @default-value-change="onDefaultParameterValueChange"
            @processing-update="onParameterProcessingUpdate"
        />
    </div>
</template>
<script>
    import { useDeepTwoWayBinding } from "@/shared/composables/useDeepTwoWayBinding";
    import { mapState, createNamespacedHelpers } from "vuex";
    import { getSpeedDates, SPEED_DATE, getQuickDates, QUICK_DATE } from "../report-models";
    import { REPORT_ACTIONS } from "@/store/actions";
    import DynamicParameter from './DynamicParameter';
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    import { useVuelidate } from "@vuelidate/core";

    const reportStoreHelpers = createNamespacedHelpers('reports');

    export default {
        name: 'DynamicParameters',
        components: {
            DynamicParameter,
        },
        props: {
            reportOptions: { type: Object, default: () => ({})},
            parameters: { type: Array, default: () => []},
            parameterValues: { type: Object, default: () => ({})},
            isInitializing: { type: Boolean, default: true },
            isBusy: { type: Boolean, default: false },
            requiredCount: { type: Number, default: 0 }
        },
        setup(props, { emit }) {
            const v$ = useVuelidate();
            const localRefs = useDeepTwoWayBinding(props, emit, {
                parameterValuesLocal: "parameterValues"
            });
            return {
                v$,
                ...localRefs
            };
        },
        data () {
            return {
                speedDates: SPEED_DATE.lookupItems,
                speedDateValue: null, //SPEED_DATE_TYPE.NEXT_QUARTER,
                quickDates: QUICK_DATE.lookupItems,
                quickDateValue: null,
                grouping: null,
                defaultErrorMessage: 'Please correct the highlighted errors on screen to continue.',
                processingStates: []
            };
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...reportStoreHelpers.mapGetters([
                "beginningBalance",
                "reportInfo"
            ]),
            userParameters() {
                return _.filter(this.parameters, (pm) => pm.name !== 'SpeedDate' && !_.isEmpty(pm.label) && pm.name !== 'QuickDate' );
            },
            hasSpeedDate() {
                return _.some(this.parameters, { name: 'SpeedDate' });
            },
            hasQuickDate() {
                return _.some(this.parameters, { name: 'QuickDate' });
            },
            groups() {
                return _.get(this.reportOptions, 'groups') || [];
            },
            canInitializeSpeedDate() {
                return this.hasSpeedDate
                    && _.isNil(_.get(this.parameterValuesLocal, 'p_StartDate'))
                    && _.isNil(_.get(this.parameterValuesLocal, 'p_EndDate'));
            },
            canInitializeQuickDate() {
                return this.hasQuickDate
                    && _.isNil(_.get(this.parameterValuesLocal, 'p_StartDate'))
                    && _.isNil(_.get(this.parameterValuesLocal, 'p_EndDate'));
            },
            hasStartAndEndDates() {
                return _.filter(this.parameters, (pm) => pm.name == 'p_StartDate' || pm.name == 'p_EndDate').length == 2;
            },
            isBusyValue: {
                get() { return this.isBusy; },
                set(val) { this.$emit("update:isBusy", val); }
            }
        },
        watch: {
            quickDateValue(newVal, oldVal) {
                if(newVal === oldVal) return;
                this.generateDateList(newVal, "QuickDate");
            },
            speedDateValue(newVal, oldVal) {
                if(newVal === oldVal) return;
                this.generateDateList(newVal, "SpeedDate");
            },
            grouping(newVal, oldVal) {
                const self = this;
                self.$emit('group-changed', { value: newVal })
            },
            beginningBalance(newVal) {
                const self = this;
                _.set(self.parameterValuesLocal, 'p_BeginningBalance', newVal);
            },
        },
        validations() {
            const self = this;

            if (!self.hasStartAndEndDates) return {};

            return _.merge({}, {
                parameterValuesLocal: {
                    validDates(val) {
                        if (!self.parameterValuesLocal.p_StartDate || !self.parameterValuesLocal.p_EndDate) return true;
                        return self.parameterValuesLocal.p_StartDate <= self.parameterValuesLocal.p_EndDate;
                    },
                }
            });
        },
        created() {
            const self = this;

            if (!_.isEmpty(self.groups)) {
                self.grouping = _.get(_.head(self.groups), 'id');
            }
        },
        mounted() {
            const self = this;

            if (self.canInitializeSpeedDate) {
                self.speedDateValue = self.getDateListDefaultValue("SpeedDate");
            }

            if (self.canInitializeQuickDate) {
                self.quickDateValue = self.getDateListDefaultValue("QuickDate");
            }
        },
        methods: {
            ...reportStoreHelpers.mapActions({
                setDates: REPORT_ACTIONS.SET_PARAMETER_DATES,
                setStoreValue: REPORT_ACTIONS.SET_PARAMETER_VALUE,
                getActivityDate: REPORT_ACTIONS.GET_ACTIVITY_DATE,
            }),
            generateDateList(dateValue, listName) {
                const self = this;

                if(_.isNil(dateValue)) return;

                let startDate = null;
                let endDate = null;

                let speedDate = listName === "QuickDate"
                    ? getQuickDates(dateValue)
                    : getSpeedDates(dateValue);

                startDate = speedDate.startDate;
                endDate = speedDate.endDate;

                _.set(self.parameterValuesLocal, 'p_StartDate', startDate);
                _.set(self.parameterValuesLocal, 'p_StaticStartDate', startDate);
                _.set(self.parameterValuesLocal, 'p_EndDate', endDate);
                _.set(self.parameterValuesLocal, 'p_StaticEndDate', endDate);

                let promise = self.setDates({ startDate: startDate, endDate: endDate });

                self.$rqBusy.wait(promise);
            },

            getDateListDefaultValue(name) {
                const self = this;
                let dateParam = _.find(self.parameters, { name });

                if(_.isEmpty(dateParam)) return;

                let dateList = name === "SpeedDate" ? self.speedDates : self.quickDates;
                let defaultVal = _.getNumber(dateParam, "defaultValue", null) || 0;

                return defaultVal > 0
                    ? defaultVal
                    : _.getFirstNumber(dateList, "id", 0);
            },

            setActivityDate(bankId){
                const self = this;
                
                if (self.reportInfo.label == "Reconciliation Daily Report"){
                    self.getActivityDate(bankId).then(result => {
                        _.set(self.parameterValuesLocal, result.key, result.value);
                        self.setStoreValue({ key: result.key, value: result.value });
                    });
                }
            },
            hasError(parameter) {
                return !!_.get(this, `v$.parameterValuesLocal.${parameter}.$error`, false)
            },
            isValid() {
                const self = this;

                // Parameter checks.
                let validParameterChecks = _.map(self.$refs.parameter, (pm) => pm.isValid());
                let areParametersValid = _.every(validParameterChecks);

                let message = self.defaultErrorMessage;

                // Top-level validation.
                self.v$.$touch();

                let startDateParam = _.find(self.userParameters, { name: 'p_StartDate' });
                let endDateParam = _.find(self.userParameters, { name: 'p_EndDate' });

                if (self.v$?.parameterValuesLocal?.validDates?.$invalid) {
                    message = `The ${startDateParam.label} must be on or before the ${endDateParam.label}.`;
                }

                let isValid = areParametersValid && !self.v$.$error;

                if(isValid && self.requiredCount > 0) {
                    let valueCount = _.sumBy(self.$refs.parameter, p => p.hasValue ? 1 : 0);
                    if(valueCount < self.requiredCount) {
                        isValid = false;
                        let fieldWord = self.requiredCount === 1 ? "field" : "fields";
                        message = `Entering a value in at least ${self.requiredCount} ${fieldWord} is required.`
                    }
                }

                self.$emit('on-validation', { hasError: !isValid, message: message });

                return isValid;
            },
            onParameterValueChange(e) {
                const self = this;
                _.set(self.parameterValuesLocal, e.name, e.value);
                self.setStoreValue({ key: e.name, value: e.value });

                switch(e.name){
                    case 'p_StaticStartDate':
                        _.set(self.parameterValuesLocal, 'p_StartDate', e.value);
                        self.setStoreValue({ key: 'p_StartDate', value: e.value });
                        break;
                    case 'p_StartDate':
                        _.set(self.parameterValuesLocal, 'p_StaticStartDate', e.value);
                        self.setStoreValue({ key: 'p_StaticStartDate', value: e.value });
                        break;
                    case 'p_StaticEndDate':
                        _.set(self.parameterValuesLocal, 'p_EndDate', e.value);
                        self.setStoreValue({ key: 'p_EndDate', value: e.value });
                        break;
                    case 'p_EndDate':
                        _.set(self.parameterValuesLocal, 'p_StaticEndDate', e.value);
                        self.setStoreValue({ key: 'p_StaticEndDate', value: e.value });
                        break;
                    case 'p_Bank': 
                       self.setActivityDate(e.value);
                        break;
                }
            },
            onDefaultParameterValueChange(e) {
                const self = this;
                self.onParameterValueChange(e);
            },
            onParameterProcessingUpdate(e) {
                let stateIndex = _.findIndex(this.processingStates, { name: e.name });
                if(stateIndex < 0)
                    this.processingStates.push({ name: e.name, isProcessing: e.value  });
                else
                    this.processingStates[stateIndex].isProcessing = e.value;
                this.isBusyValue = _.some(this.processingStates, "isProcessing");
            }
        }
    }
</script>