<template>
    <div v-if="isReady">
        <file-number-input
            v-if="dataType === dataTypes.ORDER"
            ref="component"
            :id="clientId"
            automation_id="property_value"
            :label="parameterDefinition.label"
            v-model:order-id="localValue"
            v-model:has-error="invalidOrder"
            :required="isRequired"
            v-model:is-processing="fileNumProcessing"
            v-model="gfNo"
            show-search-button
            show-clear-button
        />
        <div v-else :class="{ 'form-group': true, 'has-error': v$?.localValue?.$error, 'form-required': isRequired }">
            <label v-if="dataType != dataTypes.BOOLEAN" :for="clientId">{{parameterDefinition.label}}</label>
            <label v-else for="property_value" />
            <!-- Generics -->
            <rqdx-date-box
                v-if="dataType === dataTypes.DATE"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                type="date"
                v-model="v$.localValue.$model"
            />
            <rq-input-money
                v-else-if="dataType === dataTypes.MONEY"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                v-model="v$.localValue.$model"
            />
            <dx-select-box
                v-else-if="dataType === dataTypes.SINGLE_SELECT"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                v-model="v$.localValue.$model"
                value-expr="id"
                display-expr="name"
                :items="availableSelections"
                :search-enabled="true"
            />
            <dx-tag-box
                v-else-if="dataType === dataTypes.MULTI_SELECT"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                class="form-control"
                :items="availableSelections"
                value-expr="id"
                display-expr="name"
                :show-selection-controls="true"
                :show-clear-button="true"
                :max-displayed-tags="2"
                :show-multi-tag-only="false"
                :show-drop-down-button="true"
                v-model="v$.localValue.$model"
            />
            <input
                v-else-if="dataType === dataTypes.TEXT"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                v-model="v$.localValue.$model"
                type="text"
                class="form-control rq-placeholder-visible"
                placeholder="All Descriptions"
            />
            <!-- <div v-else-if="dataType == dataTypes.BOOLEAN" class="label-lg"> -->
            <b-form-checkbox
                ref="component"
                v-else-if="dataType === dataTypes.BOOLEAN"
                automation_id="property_value"
                v-model="v$.localValue.$model"
            >
                {{parameterDefinition.label}}
            </b-form-checkbox>
            <!-- </div> -->
            <input
                v-else-if="dataType === dataTypes.NUMBER"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                v-model="v$.localValue.$model"
                type="number"
                class="form-control"
            />
            <company-picker
                v-else-if="dataType === dataTypes.COMPANY"
                ref="component"
                automation_id="property_value"
                :dialog-title="`Select ${parameterDefinition.label}`"
                v-model:company-id="v$.localValue.$model"
                auto-resolve
            />
            <rq-validation-feedback
                v-if="dataType != dataTypes.ORDER"
                message="Value is required."
            />
            <rq-radio-group
                v-if="dataType === dataTypes.RADIO"
                ref="component"
                :id="clientId"
                automation_id="property_value"
                v-model="v$.localValue.$model"
                :options="availableSelections"
                size="sm"
                inline
            />
        </div>
    </div>
</template>

<script>
    import { computed, ref, watch, nextTick } from "vue";
    import { useStore } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import { requiredIf } from "@vuelidate/validators";

    import OrdersApi from "@/api/orders";

    import { DATA_TYPE, BiParameterReportDto } from "../report-models";
    import { FileNumberInput, CompanyPicker } from "@/shared/components/rq/";

    export default {
        name: "DynamicParameter",
        emits: ["value-change", "default-value-change", "processing-update"],
        components: {
            FileNumberInput,
            CompanyPicker
        },
        props: {
            parameterDefinition: { type: Object, default: new BiParameterReportDto()},
            value: { type: [Array,String,Number,Object,Boolean], default: () => null },
            allParameters: { type: Object, default: () => ({})},
            isInitializing: { type: Boolean, default: true }
        },
        setup(props, { emit, expose }) {
            const store = useStore();
            const getLookupSelections = store.getters["reports/getLookupSelections"];

            const clientId = _.uniqueId("report_parameter_");
            const dataTypes = { ...DATA_TYPE };

            const valueProp = computed(() => props.value);
            const localValue = ref(props.value);

            const fileNumProcessing = ref(false);
            const gfNo = ref(null);
            const invalidOrder = ref(false);

            const isReady = ref(true);

            const availableSelections = computed(() => {
                let key = props.parameterDefinition.lookupKey;
                let showInactive = _.parseBool(props.parameterDefinition.showInactive, false);
                let lookupSelections = getLookupSelections(key, showInactive);
                props.parameterDefinition.availableSelectionsCount = lookupSelections.length;
                return lookupSelections;
            });

            const configuredDefaultValue = computed(() => props.parameterDefinition?.defaultValue || null);
            const dataType = computed(() => {
                switch (_.toUpper(props.parameterDefinition?.dataType)) {
                    case "DATE": return DATA_TYPE.DATE;
                    case "MONEY": return DATA_TYPE.MONEY;
                    case "TEXT": return DATA_TYPE.TEXT;
                    case "NUMBER": return DATA_TYPE.NUMBER;
                    case "SINGLESELECT": return DATA_TYPE.SINGLE_SELECT;
                    case "MULTISELECT": return DATA_TYPE.MULTI_SELECT;
                    case "ORDER": return DATA_TYPE.ORDER;
                    case "COMPANY": return DATA_TYPE.COMPANY;
                    case "BOOLEAN": return DATA_TYPE.BOOLEAN;
                    case "RADIO": return DATA_TYPE.RADIO
                    default: return DATA_TYPE.TEXT;
                }
            });

            const defaultValue = computed(() => {
                if(!_.isNil(configuredDefaultValue.value)){
                    if (dataType.value == DATA_TYPE.MULTI_SELECT){
                        //RQO-32118: Paramaters defaulting to "Select..."
                        if (_.toUpper(configuredDefaultValue.value) == "SELECT..."){
                            return null;
                        }
                        else {
                            return configuredDefaultValue.value.split(',');
                        }
                    }
                    else{
                        return configuredDefaultValue.value;
                    }
                }

                switch (dataType.value) {
                    case DATA_TYPE.BOOLEAN: return false;
                    case DATA_TYPE.SINGLE_SELECT: return _.get(_.head(availableSelections.value), "id");
                    case DATA_TYPE.RADIO: return _.get(_.head(availableSelections.value), "value");
                    case DATA_TYPE.MULTI_SELECT: return _.map(availableSelections.value, "id");
                }

                return null;
            });

            const hasValue = computed(() => {
                switch (dataType.value) {
                    case DATA_TYPE.MONEY:
                    case DATA_TYPE.NUMBER:
                    case DATA_TYPE.ORDER: return _.parseNumber(localValue.value, 0) !== 0;
                    case DATA_TYPE.TEXT:
                    case DATA_TYPE.RADIO:
                    case DATA_TYPE.MULTI_SELECT: return !_.isEmpty(localValue.value);
                    case DATA_TYPE.BOOLEAN: return _.isBoolean(localValue.value);
                    default: return !_.isNil(localValue.value);
                }
            });

            const isRequired = computed(() => _.getBool(props, "parameterDefinition.required") && dataType.value !== DATA_TYPE.BOOLEAN);

            const rules = computed(() => ({
                localValue: {
                    required: requiredIf(() => isRequired.value)
                }
            }));
            const v$ = useVuelidate(rules, { localValue });

            watch(valueProp, (newValue, oldValue) => {
                if(newValue === oldValue || newValue === localValue.value) return;
                localValue.value = newValue;
            }, { deep: true });

            watch(localValue, (newValue, oldValue) => {
                if(newValue === oldValue || newValue === valueProp.value) return;
                emitValueEvent("value-change", newValue);
            }, { deep: true });

            watch(availableSelections, () => {
                isReady.value = false;
                setDefaultValue(!props.isInitializing);
                nextTick(() => {
                    isReady.value = true;
                });
            }, { deep: true });

            watch(fileNumProcessing, newValue => {
                emitValueEvent("processing-update", newValue);
            });

            function emitValueEvent(eventName, value) {
                emit(eventName, { name: props.parameterDefinition.name, value });
            }

            function isValid() {
                v$.value.$touch();
                return !v$.value.$error && !invalidOrder.value;
            }

            async function setDefaultValue(reset=false) {
                if(hasValue.value && dataType.value === DATA_TYPE.ORDER) {
                    gfNo.value = await OrdersApi.getGfNo(localValue.value);
                }

                if(!reset && hasValue.value) return;

                localValue.value = defaultValue.value;

                emitValueEvent("default-value-change", defaultValue.value);
            }

            setDefaultValue();

            expose({
                hasValue,
                isValid
            });

            return {
                v$,
                clientId,
                localValue,
                dataType,
                fileNumProcessing,
                gfNo,
                availableSelections,
                isRequired,
                isReady,
                dataTypes
            }
        }
    }

</script>